module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"offsetY":"100","elements":["h2","h3"]}},"gatsby-remark-prismjs"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Laboperator Docs","short_name":"Laboperator","start_url":"/","background_color":"#fff","theme_color":"#ffad00","display":"standalone","icon":"src/images/laboperator_favicon_32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3190ff4ee3d030c19382958b005164ce"},
    },{
      plugin: require('../node_modules/@labforward/gatsby-theme-laboperator-docs/gatsby-browser.js'),
      options: {"plugins":[],"gitSources":[{"remote":"git@github.com:labforward/laboperator-workflow-schema","branch":"main","patterns":["src/schemata/**/*.yml"]}]},
    }]
