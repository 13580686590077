import { FC, useState, useMemo } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Fuse from 'fuse.js';

import Link from '@labforward/gatsby-theme-laboperator-docs/src/components/Link';
import useSchemaIndex, { SchemaEntry } from '../hooks/useSchemaIndex';

const searchFilter = (entries: SchemaEntry[]) => {
  const list = entries.map((doc, idx) => ({
    item: doc,
    score: 1,
    refIndex: idx,
  }));

  const fuse = new Fuse(entries, {
    threshold: 0.4,
    keys: [{ name: 'title', weight: 2 }, 'slug'],
  });

  return (query: string) => {
    const results = fuse.search(query);

    return query.length > 0 ? results : (list as typeof results);
  };
};

const SchemaIndex: FC = () => {
  const schemaEntries = useSchemaIndex();
  const [query, setQuery] = useState('');
  const search = useMemo(() => searchFilter(schemaEntries), [schemaEntries]);

  return (
    <div>
      <TextField
        id="schema-search"
        label="Search for a schema"
        type="search"
        variant="outlined"
        value={query}
        onChange={(event): void => setQuery(event.target.value)}
      />
      <List>
        {search(query).map(({ item: { slug, title } }) => (
          <li key={slug}>
            <ListItem component={Link} button to={slug}>
              <ListItemText primary={title} secondary={slug} />
            </ListItem>
          </li>
        ))}
      </List>
    </div>
  );
};

export default SchemaIndex;
