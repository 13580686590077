import { graphql, useStaticQuery } from 'gatsby';

export interface SchemaEntry {
  slug: string;
  title: string;
  json: string;
}

const useSchemaIndex = (): SchemaEntry[] => {
  const result = useStaticQuery(graphql`
    {
      allSchema(sort: { fields: title, order: ASC }) {
        nodes {
          slug
          title
          json
        }
      }
    }
  `);

  return result.allSchema.nodes;
};

export default useSchemaIndex;
